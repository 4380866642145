<template>
  <div>
    <div class="print" style="font-size: 36px;">
      {{ $t('param.a3') }}
      <el-button v-print="'printObj'" type="primary" @click="printClick"
        v-loading.fullscreen.lock="fullscreenLoading">{{ $t('common.a12') }}
      </el-button>
    </div>
    <!-- search -->
    <div class="flex mar30">
      <div class="flex" v-if="quanxian == 'Config' || quanxian == 'Admin'" style="width: 60%;">
        <div style="width: 89%;">
          <input v-model="search" type="text" class="search" :placeholder="$t('param.a26')">
        </div>
        <div class="search-btn" @click="getList"><i class="el-icon-search"></i></div>
      </div>
      <!--权限控制-->
      <div v-if="edit == 'false' || quanxian == 'Config' || quanxian == 'Admin'">
        <el-button size="large" type="primary" style="margin-right: 30px;" @click="centerDialogVisible = true">
          {{ $t('common.a1') }}
        </el-button>
        <el-button size="large" @click="deleteAll">{{ $t('common.a2') }}</el-button>
      </div>
    </div>

    <!--打印区域-->
    <div id="print_html" ref="print_html">
      <el-table :data="tableData" class="table mar30" border @selection-change="checkSelect" height="450px"
        style="width: 100%;">
        <el-table-column type="selection" width="60">
        </el-table-column>
        <el-table-column type="index" :label="$t('param.index')" sortable width="180">
        </el-table-column>
        <el-table-column prop="name" sortable :label="$t('param.a12')">
        </el-table-column>
        <el-table-column prop="number" sortable :label="$t('param.number')">
        </el-table-column>
      </el-table>
    </div>
    <div style="text-align: center;">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
        :page-size="pageSize" background layout="prev, pager, next" :total="total">
      </el-pagination>
    </div>
    <!-- button -->
    <div class="flex mar30 next-btn" v-show="edit != 'true'">
      <el-button size="large" type="primary" @click="back">{{ $t('common.a5') }}</el-button>
      <el-button size="large" type="primary" @click="go">{{ $t('common.a6') }}</el-button>
    </div>
    <el-dialog :title="$t('common.a1')" :visible.sync="centerDialogVisible" :close-on-click-modal="false" width="30%"
      center>
      <el-form label-position="right" label-width="120px" :rules="rules" :model="form">
        <el-form-item :label="$t('param.numberType')" prop="numberType">
          <el-radio-group v-model="form.numberType">
            <el-radio label="MSISDN">{{ $t('param.a27') }}</el-radio>
            <el-radio label="VGCS">{{ $t('param.a28') }}</el-radio>
            <el-radio label="VBS">{{ $t('param.vbs') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('param.a12')" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('param.number')" prop="number">
          <el-input :maxlength="20" v-model="form.number" @input="inputCheck"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">cancel</el-button>
        <el-button type="primary" :disabled="!form.number" @click="save">{{ $t('common.a4') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  data() {
    return {
      pid: '',
      pageNum: 1,
      pageSize: 10,
      total: 0,
      search: '',
      edit: 'false',
      tableData: [],
      centerDialogVisible: false,
      form: {
        name: '',
        number: '',
        numberType: 'VGCS'
      },
      rules: {
        name: [
          { required: true, message: '', trigger: 'blur' },
        ],
      },
      deleteList: [],
      quanxian: '',
      printObj: {
        id: 'print_html', //打印标签的id
      },
      fullscreenLoading: false,
    }
  },
  created() {
    let data = sessionStorage.getItem('file')
    if (data) {
      this.pid = JSON.parse(data).id;
    }
    this.edit = sessionStorage.getItem('edit');
    this.getList();

    let user = sessionStorage.getItem('user');
    if (user) this.quanxian = JSON.parse(user).quanxian;
  },
  beforeRouteLeave(to, from, next) {
    const lastTime = sessionStorage.getItem('control')
    if (this._logoutIfTimeout(lastTime)) {
      next()
      return;
    }
    if (this.edit == 'true' || (
      to.path == '/Contacts' ||
      to.path == '/BasicParameters' ||
      to.path == '/Menus' ||
      to.path == '/Shortcutkeys' ||
      to.path == '/ForbiddenNumbers'
    )) {
      return next();
    }
    this.$confirm(this.$t('message.a11'), this.$t('message.a10'), {
      confirmButtonText: this.$t('common.a4'),
      cancelButtonText: this.$t('cp.a5'),
      type: 'warning'
    }).then(() => {
      if (this.pid) {
        this.postRequest('index/del_phone_profile', { ids: this.pid }, () => {
        })
      }
      next();
    }).catch(() => {
      next(false)
    });
  },

  methods: {
    printClick() {
      this.fullscreenLoading = true;
      this.html2canvas(this.$refs.print_html, {
        backgroundColor: null,
        useCORS: true,
        windowHeight: document.body.scrollHeight
      }).then((canvas) => {
        const url = canvas.toDataURL()
        this.printJS({
          printable: url,
          type: 'image',
          documentTitle: ''
        })
        this.fullscreenLoading = false;
      });
    },
    getList() {
      let params = {
        page: this.pageNum,
        page_size: this.pageSize,
        uid: sessionStorage.getItem('id'),
        pid: this.pid,

      }
      if (this.search) {
        params.search = this.search;
      }
      this.postRequest('index/get_contacts', params, res => {
        this.tableData = res.data.info;
        this.total = res.data.count;
      })
    },

    inputCheck() {
      this.form.number = this.form.number.replace(/[^0-9*+#]*/g, '').substr(0, 19)
    },
    save() {
      const lastTime = sessionStorage.getItem('control')
      if (this.logoutIfTimeout(lastTime)) {
        return;
      }
      if (!(
        this.form.name &&
        this.form.number)) return;
      this.postRequest('index/add_contacts', {
        uid: sessionStorage.getItem('id'),
        pid: this.pid,
        name: this.form.name,
        number: this.getPrefixMMICode(this.form.numberType) + this.form.number
        //...this.form
      }, () => {
        this.getList();
        this.form = {
          name: '',
          number: '',
          numberType: 'VGCS'
        }
        this.centerDialogVisible = false
        if (this.pid && this.edit == 'true') {
          this.uploadXML();
        }
      })
    },
    uploadXML() {
      this.postRequest('index/create_xml', {
        id: this.pid,
        uid: sessionStorage.getItem('id')
      }, () => {
        this.$message({
          type: 'success',
          message: this.$t('message.a5')
        });
      })
    },

    checkSelect(e) {
      this.deleteList = e.map(v => v.id)
    },
    deleteAll() {
      if (this.deleteList.length == 0) return;
      // this.$confirm('是否删除选中的'+ this.deleteList.length+'条数据', this.$t('common.a2'), {
      this.$confirm(this.$t('common.a2') + '?', this.$t('message.a10'), {
        confirmButtonText: this.$t('cp.a4'),
        cancelButtonText: this.$t('cp.a5'),
        type: 'warning'
      }).then(() => {
        this.postRequest('index/del_contacts', { ids: this.deleteList.join(',') }, () => {
          this.postRequest('index/create_xml', {
            id: this.pid,
            uid: sessionStorage.getItem('id')
          }, () => {
            this.$message({
              type: 'success',
              message: this.$t('message.a6')
            });
          })
          this.getList();
        })
      })
    },

    back() {
      this.$root.status = 'BasicParameters';
      this.$router.go(-1);
    },
    go() {
      this.$router.push('/Menus')
      this.$root.status = 'Menus';
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    getPrefixMMICode(numberType) {
      switch (numberType) {
        case 'MSISDN':
          return '';
        case 'VGCS':
          return '*17#';
        case 'VBS':
          return '*18#';
        default:
          return ''; // or some other default value
      }
      //Alternatively
      // const lookup = {
      //   MSISDN: '',
      //   VGCS: '*17#',
      //   VBS: '*18#'
      // };
      // return lookup[radio]
    },
    getNumberType(PrefixMMICode) {
      switch (PrefixMMICode) {
        case '':
          return 'MSISDN';
        case '*17#':
          return 'VGCS';
        case '*18#':
          return 'VBS';
        default:
          return ''; // or some other default value
      }
      //Alternatively
      // const lookup = {
      //   '': 'MSISDN',
      //   '*17#': 'VGCS',
      //   '*18#': 'VBS'
      // };
      // return lookup[value];
    },
    getPrefixMMICodeByNumber(number) {
      if (number.startsWith('*17#')) {
        return '*17#';
      } else if (number.startsWith('*18#')) {
        return '*18#';
      } else {
        return '';
      }
      //Alternatively
      // const regex = /^(\*17#|\*18#)/;
      // const match = str.match(regex);
      // return match ? match[1] : '';
    }
  }
}
</script>

<style scoped>
.search {
  width: 100%;
  height: 46px;
  border: 3px solid #5D80F5;
  border-radius: 1px;
  font-size: 18px;
  text-indent: 20px;
  box-sizing: border-box;

}

.search:focus-visible {
  outline: none;
}

.search-btn {
  width: 11%;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 22px;
  cursor: pointer;
  color: #C3D0FB;
  background: #5D80F5;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.next-btn {
  margin-bottom: 30px;
}

.mar30 {
  margin-top: 30px;
}

.print {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
</style>
